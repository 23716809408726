<template>
  <div>
      <div class="top">
          <div class="chart">
              <img :src="avatar" />
          </div>
          <div class="userName">{{ name }}</div>
      </div>
      <van-cell-group>
          <van-cell title="文档查询" icon="description" size="large" is-link />
          <van-cell title="修改密码" icon="more-o" size="large" is-link @click="show = true"  />
          <van-cell title="关于我们" icon="user-o" size="large" is-link />
          <van-cell title="退出登录" icon="guide-o" size="large" is-link @click="logout" />
      </van-cell-group>
      <van-overlay :show="show" @click="show = false">
          <div class="wrapper" @click.stop>
              <div class="block" >
                  <van-form @submit="passwordChange">
                    <van-field v-model="password.p1" name="密码" label="密码" placeholder="密码" style="margin-top: 20px" :rules="[{ required: true, message: '请填写用户名' }]"/>
                     <van-field v-model="password.p2" type="password" name="确认密码" label="确认密码" placeholder="确认密码" :rules="[{ required: true, message: '请填写密码' }]"/>
                     <div class="btn">
                     <van-button round size="small" type="info" native-type="submit" style="margin-bottom: 20px;width: 100px">提交</van-button>
                     <van-button round size="small" type="info" style="margin-bottom: 20px;width: 100px" @click="cancel">取消</van-button></div>
                  </van-form>
              </div>
          </div>
      </van-overlay>
  </div>
</template>

<script>
import API from '@/api/personnel/admin'
import { mapGetters } from 'vuex'
import { Toast } from 'vant'
export default {
    inject: ['goUrl'],
    name: "minePage",
    data() {
        return {
            show: false,
            password: {
                p1: '',
                p2: '',
                id: ''
            }
        }
    },
    computed: {
        ...mapGetters(['name', 'avatar'])
    },
    created() {
        console.log(name)
    },
    methods: {
        async logout() {
            await this.$store.dispatch('user/logout')
            await this.$router.push('/login')
        },
        cancel() {
            this.show = false
        },
        passwordChange() {
            API.changePassword(this.password).then(res => {
                if (res.data.error_code !== 1) {
                    console.log('2')
                    Toast.fail('操作失败')
                } else {
                    console.log('3')
                    Toast.success('修改成功')
                    this.cancel()
                }
            })
        }
    }

}
</script>
<style scoped>
    .top{
        height: 190px;
        background: url("../../assets/index-top-bgc.png");
        background-size: cover;
        overflow: hidden;
    }
    .chart{
        width: 90px;
        height: 90px;
        margin: 40px auto;

    }
    .chart img{
        width: 90px;
        height: 90px;
        border-radius: 50px;
    }
    .userName {
        color: #fff;
        text-align: center;
        margin-top: -30px;
        font-size: 18px;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 80vw;
        height: 30vh;
    }
    .btn{
        display: flex;
        justify-content:space-around;
        margin-top: 60px;
    }
</style>
